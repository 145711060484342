import {createSlice} from '@reduxjs/toolkit';
import {RegistyAPI} from './methods';
import {SOURCE_TYPE} from '../../common';

const initialState = {
  contracts: [],
  sources: [],
  conclusionCertificates: [],
  customerInfo: {},
  listeners: [],
  listenersForAdd: [],
  listenerPrograms: [],
  listenerFullInfo: {},
  programsLoading: false,
  scoreInfo: null,
  delivery: {},
  testingResults: {},
  docsLoading: false,
  availableFunctions: {},
  documents: [],
  handMadeDocuments: [],
  contractNumber: null,
  contractQueryCount: 0,
  listenersQueryCount: 0,
  relatedContracts: {},
  relatedContractsFull: [],
  promoCodes: [{id: 1, code: '', discount: ''}],
  contractInfo: {},
  totalCount: 0,
  pageSize: 500,
  customerId: '',
  loading: false,
};

const registry = createSlice({
  name: 'registy',
  initialState,
  reducers: {},
  extraReducers: {
    [RegistyAPI.registry.getAll.pending]: (state, action) => {
      state.loading = true;
    },
    [RegistyAPI.registry.getAll.fulfilled]: (state, action) => {
      state.contracts = action.payload.data;
      state.totalCount = action.payload.totalCount;
      state.pageSize = action.payload.limit;
      state.loading = false;
    },
    [RegistyAPI.registry.getAll.rejected]: (state, action) => {
      state.contracts = [];
      state.loading = false;
    },

    [RegistyAPI.registry.getCountContractByFilter.fulfilled]: (state, action) => {
      state.contractQueryCount = action.meta.arg.isNeedDiplomCheck
        ? action.payload.filterCount
        : state.contractQueryCount;
      state.listenersQueryCount = action.meta.arg.isStudentChangeQueryActive
        ? action.payload.filterCount
        : state.listenersQueryCount;
    },

    [RegistyAPI.operator.set.pending]: (state, action) => {
      state.loading = true;
    },
    [RegistyAPI.operator.set.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [RegistyAPI.operator.set.rejected]: (state, action) => {
      state.loading = false;
    },

    [RegistyAPI.operator.uploadHandMade.pending]: (state, action) => {
      state.loading = true;
    },
    [RegistyAPI.operator.uploadHandMade.fulfilled]: (state, action) => {
      state.loading = false;
      state.handMadeDocuments = [...state.handMadeDocuments, action.payload];
      state.documents = state.documents.map((document) => {
        if (document.documentTypeId === action.payload.documentTypeId) {
          return action.payload.fileExt === 'docx' ? {...document, docxManual: true} : {...document, pdfManual: true};
        }
        return document;
      });
    },
    [RegistyAPI.operator.uploadHandMade.rejected]: (state, action) => {
      state.loading = false;
    },

    [RegistyAPI.operator.updateHandMade.pending]: (state, action) => {
      state.loading = true;
    },
    [RegistyAPI.operator.updateHandMade.fulfilled]: (state, action) => {
      state.loading = false;
      state.handMadeDocuments = state.handMadeDocuments.map((handMadeDocument) =>
        handMadeDocument.id === action.payload.id ? action.payload : handMadeDocument
      );
    },
    [RegistyAPI.operator.updateHandMade.rejected]: (state, action) => {
      state.loading = false;
    },

    [RegistyAPI.operator.getAllChangedDocuments.pending]: (state, action) => {
      state.loading = true;
    },
    [RegistyAPI.operator.getAllChangedDocuments.fulfilled]: (state, action) => {
      state.loading = false;
      state.handMadeDocuments = action.payload.data;
    },
    [RegistyAPI.operator.getAllChangedDocuments.rejected]: (state, action) => {
      state.loading = false;
    },

    [RegistyAPI.operator.deleteChangedDocument.pending]: (state, action) => {
      state.loading = true;
    },
    [RegistyAPI.operator.deleteChangedDocument.fulfilled]: (state, action) => {
      state.loading = false;
      state.documents = state.documents.map((document) => {
        const deletedDocument = state.handMadeDocuments.find((document) => document.id === action.meta.arg);
        if (document.documentTypeId === deletedDocument.documentTypeId) {
          return deletedDocument.fileExt === 'docx'
            ? {...document, docxManual: false}
            : {...document, pdfManual: false};
        }
        return document;
      });
      state.handMadeDocuments = state.handMadeDocuments.filter((document) => document.id !== action.meta.arg);
    },
    [RegistyAPI.operator.deleteChangedDocument.rejected]: (state, action) => {
      state.loading = false;
    },

    [RegistyAPI.listeners.contractInfo.pending]: (state, action) => {
      state.loading = true;
    },
    [RegistyAPI.listeners.contractInfo.fulfilled]: (state, action) => {
      state.loading = false;
      state.contractInfo = {...action.payload};
    },
    [RegistyAPI.listeners.contractInfo.rejected]: (state, action) => {
      state.loading = false;
    },

    [RegistyAPI.listeners.getAll.pending]: (state, action) => {
      state.loading = true;
    },
    [RegistyAPI.listeners.getAll.rejected]: (state, action) => {
      state.loading = false;
    },
    [RegistyAPI.listeners.getAll.fulfilled]: (state, action) => {
      state.loading = false;
      state.listenersForAdd = action.payload.studentListForAdd;
      state.listeners = action.payload.studentList;
    },

    [RegistyAPI.note.update.pending]: (state, action) => {
      state.loading = true;
    },
    [RegistyAPI.note.update.fulfilled]: (state, action) => {
      state.contracts = state.contracts.map((contract) =>
        contract.id === action.meta.arg.id ? {...contract, note: action.meta.arg.note.note} : contract
      );
      state.loading = false;
    },
    [RegistyAPI.note.update.rejected]: (state, action) => {
      state.loading = false;
    },

    [RegistyAPI.score.getByContractId.pending]: (state, action) => {
      state.scoreInfo = null;
    },
    [RegistyAPI.score.getByContractId.fulfilled]: (state, action) => {
      state.scoreInfo = action.payload;
    },

    [RegistyAPI.score.update.pending]: (state, action) => {
      state.loading = true;
    },
    [RegistyAPI.score.update.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [RegistyAPI.score.update.rejected]: (state, action) => {
      state.loading = false;
    },

    [RegistyAPI.delivery.getById.pending]: (state, action) => {
      state.loading = true;
    },
    [RegistyAPI.delivery.getById.fulfilled]: (state, action) => {
      state.loading = false;
      state.delivery = action.payload;
      // state.delivery.trackNumbers = action.payload.trackNumbers.map((track) => {
      // 	return { ...track, exists: true };
      // });
    },

    [RegistyAPI.trackNumbers.create.pending]: (state, action) => {
      state.loading = true;
    },
    [RegistyAPI.trackNumbers.create.fulfilled]: (state, action) => {
      state.loading = false;
      state.contracts = state.contracts.map((contract) =>
        contract.id === action.meta.arg.id ? {...contract, trackNumber: action.payload} : contract
      );
    },
    [RegistyAPI.trackNumbers.create.rejected]: (state, action) => {
      state.loading = false;
    },

    [RegistyAPI.trackNumbers.delete.pending]: (state, action) => {
      state.loading = true;
    },
    [RegistyAPI.trackNumbers.delete.fulfilled]: (state, action) => {
      state.loading = false;
      state.contracts = state.contracts.map((contract) =>
        contract.trackNumber?.id === action.meta.arg.id ? {...contract, trackNumber: null} : contract
      );
    },
    [RegistyAPI.trackNumbers.delete.rejected]: (state, action) => {
      state.loading = false;
    },

    [RegistyAPI.trackNumbers.sendPostData.pending]: (state, action) => {
      state.loading = true;
    },
    [RegistyAPI.trackNumbers.sendPostData.fulfilled]: (state, action) => {
      state.contracts = state.contracts.map((contract) =>
        contract.id === action.meta.arg.id ? {...contract, postDataReport: action.payload.data} : contract
      );
      state.loading = false;
    },
    [RegistyAPI.trackNumbers.sendPostData.rejected]: (state, action) => {
      state.loading = false;
    },

    [RegistyAPI.listeners.getPrograms.pending]: (state) => {
      state.programsLoading = true;
    },

    [RegistyAPI.listeners.getPrograms.fulfilled]: (state, action) => {
      state.programsLoading = false;
      state.listenerPrograms = action.payload.filter((program) => program.canUse);
    },
    [RegistyAPI.listeners.getPrograms.rejected]: (state, action) => {
      state.programsLoading = false;
    },

    [RegistyAPI.listeners.fullInformation.pending]: (state) => {
      state.loading = true;
    },

    [RegistyAPI.listeners.fullInformation.fulfilled]: (state, action) => {
      state.loading = false;
      state.listenerFullInfo = {...action.payload};
    },
    [RegistyAPI.listeners.fullInformation.rejected]: (state, action) => {
      state.loading = false;
    },

    [RegistyAPI.documents.getAll.pending]: (state) => {
      state.docsLoading = true;
    },

    [RegistyAPI.documents.getAll.fulfilled]: (state, action) => {
      state.docsLoading = false;
      state.documents = action.payload.documents;
      state.contractNumber = action.payload.contractNumber;
      state.customerId = action.payload.customerId;
    },
    [RegistyAPI.documents.getAll.rejected]: (state, action) => {
      state.docsLoading = false;
    },

    [RegistyAPI.documents.getAvailable.fulfilled]: (state, action) => {
      state.availableFunctions = action.payload;
    },

    [RegistyAPI.testingResults.getById.pending]: (state, action) => {
      state.loading = true;
    },
    [RegistyAPI.testingResults.getById.fulfilled]: (state, action) => {
      state.loading = false;
      state.testingResults = action.payload;
    },
    [RegistyAPI.testingResults.getById.rejected]: (state, action) => {
      state.loading = false;
    },

    [RegistyAPI.contractRelations.checkRelations.pending]: (state, action) => {
      state.loading = true;
    },
    [RegistyAPI.contractRelations.checkRelations.fulfilled]: (state, action) => {
      state.loading = false;
      state.relatedContracts = action.payload.data;
    },
    [RegistyAPI.contractRelations.checkRelations.rejected]: (state, action) => {
      state.relatedContracts = {};
      state.loading = false;
    },
    [RegistyAPI.contractRelations.availableRelations.pending]: (state, action) => {
      state.loading = true;
    },
    [RegistyAPI.contractRelations.availableRelations.fulfilled]: (state, action) => {
      state.loading = false;
      state.relatedContractsFull = action.payload.data.map((contract) => ({
        relationContractId: contract.id,
        number: Array.isArray(contract.contracts)
          ? contract.contracts
              .map((item) => item.number)
              .toString()
              .replaceAll(',', ', ')
          : contract.contracts.number,
      }));
    },
    [RegistyAPI.contractRelations.availableRelations.rejected]: (state, action) => {
      state.relatedContractsFull = [];
      state.loading = false;
    },

    [RegistyAPI.conclusion.get.pending]: (state, action) => {
      state.loading = true;
    },
    [RegistyAPI.conclusion.get.fulfilled]: (state, action) => {
      state.loading = false;
      state.conclusionCertificates = action.payload.data;
    },
    [RegistyAPI.conclusion.get.rejected]: (state, action) => {
      state.loading = false;
    },

    [RegistyAPI.source.update.pending]: (state, action) => {
      state.loading = true;
    },
    [RegistyAPI.source.update.fulfilled]: (state, action) => {
      state.loading = false;
      state.contracts = state.contracts.map((contract) => {
        return contract.id === action.meta.arg.id
          ? {
              ...contract,
              source: {
                id: action.meta.arg.sourceTypeId,
                name: SOURCE_TYPE.find((type) => type.value === action.meta.arg.sourceTypeId).name,
              },
            }
          : contract;
      });
    },
    [RegistyAPI.source.update.rejected]: (state, action) => {
      state.loading = false;
    },

    [RegistyAPI.print.pending]: (state, action) => {
      state.loading = true;
    },
    [RegistyAPI.print.fulfilled]: (state, action) => {
      state.contracts = state.contracts.map((contract) =>
        contract.id === action.meta.arg.id
          ? {...contract, printUpdateDateTime: action.payload.printUpdateDateTime}
          : contract
      );
    },
    [RegistyAPI.print.rejected]: (state, action) => {
      state.loading = false;
    },

    [RegistyAPI.registryMintrud.pending]: (state, action) => {
      state.loading = true;
    },
    [RegistyAPI.registryMintrud.fulfilled]: (state, action) => {
      state.loading = false;
      state.contracts = state.contracts.map((contract) =>
        contract.id === action.meta.arg.id
          ? {...contract, mintrudStatusDateTime: action.payload.mintrudStatus}
          : contract
      );
    },
    [RegistyAPI.registryMintrud.rejected]: (state, action) => {
      state.loading = false;
    },

    [RegistyAPI.color.get.pending]: (state, action) => {
      state.loading = true;
    },
    [RegistyAPI.color.get.fulfilled]: (state, action) => {
      state.loading = false;
      state.contracts = state.contracts.map((contract) =>
        contract.id === action.payload.data.contractId ? {...contract, color: action.payload.data.color} : contract
      );
    },
    [RegistyAPI.color.get.rejected]: (state, action) => {
      state.loading = false;
    },

    [RegistyAPI.promocode.getAll.pending]: (state, action) => {
      state.loading = true;
    },
    [RegistyAPI.promocode.getAll.fulfilled]: (state, action) => {
      state.loading = false;
      state.promoCodes = [...action.payload.data];
    },
    [RegistyAPI.promocode.getAll.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const RegistySelectors = {
  scoreInfo: (state) => state.registry.scoreInfo,
  contracts: (state) => state.registry.contracts,
  contractNumber: (state) => state.registry.contractNumber,
  listeners: (state) => state.registry.listeners,
  listenersForAdd: (state) => state.registry.listenersForAdd,
  listenerFullInfo: (state) => state.registry.listenerFullInfo,
  listenerPrograms: (state) => state.registry.listenerPrograms,
  delivery: (state) => state.registry.delivery,
  loading: (state) => state.registry.loading,
  testingResults: (state) => state.registry.testingResults,
  programsLoading: (state) => state.registry.programsLoading,
  docsLoading: (state) => state.registry.docsLoading,
  documents: (state) => state.registry.documents,
  customerId: (state) => state.registry.customerId,
  totalCount: (state) => state.registry.totalCount,
  pageSize: (state) => state.registry.pageSize,
  handMadeDocuments: (state) => state.registry.handMadeDocuments,
  contractCount: (state) => state.registry.contractQueryCount,
  listenersCount: (state) => state.registry.listenersQueryCount,
  availableFunctions: (state) => state.registry.availableFunctions,
  relatedContracts: (state) => state.registry.relatedContracts,
  relatedContractsFull: (state) => state.registry.relatedContractsFull,
  conclusionCertificates: (state) => state.registry.conclusionCertificates,
  promoCodes: (state) => state.registry.promoCodes,
  contractInfo: (state) => state.registry.contractInfo,
};

export default registry.reducer;
