import {Link, useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import dayjs from 'dayjs';
import TextArea from 'antd/lib/input/TextArea';
import {Button, Checkbox, message, Popconfirm, Select, Space, Spin, Switch, Table, Tooltip, Typography} from 'antd';

import {ADMIN_RIGHTS, DOCUMENT_TYPES, LOCALE, paramsToObject, SOURCE_TYPE} from '../../common';

import {AdminSelectors} from '../../store/AdminStore';
import {AdminAPI} from '../../store/AdminStore/methods';
import {RegistySelectors} from '../../store/RegistryStore';
import {RegistyAPI} from '../../store/RegistryStore/methods';
import EditableCell from '../UX/EditableCell';
import PopConfirmButton from '../UX/PopConfirmButton';

import PostData from './PostData';
import UseMessage from '../hooks/useMessage';
import {loginAsUser} from '../../utils/logInAsUser';
import {InfoCircleOutlined, LoadingOutlined} from '@ant-design/icons';
import {useMemo, useState} from 'react';

const {Text} = Typography;
const {REGISTRY, MESSAGES} = LOCALE;

const RegistryTable = ({fetchContracts}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const filters = paramsToObject(searchParams);

  const contracts = useSelector(RegistySelectors.contracts);
  const loading = useSelector(RegistySelectors.loading);
  const operators = useSelector(AdminSelectors.operators);
  const registryColumns = useSelector(AdminSelectors.registryColumns);
  const adminInfo = useSelector(AdminSelectors.adminInfo);
  const totalCount = useSelector(RegistySelectors.totalCount);
  const pageSize = useSelector(RegistySelectors.pageSize);

  const [folderLoading, setFolderLoading] = useState(false);

  const {showSuccess, showError} = UseMessage();

  const canChangeOperators = adminInfo?.adminRights.includes(ADMIN_RIGHTS.changeOperatorInRegistry.id);

  const columnsProps = (key = '', render, props) => {
    if (registryColumns[key])
      return {
        title: REGISTRY.COLUMNS[key.toUpperCase()],
        align: 'center',
        dataIndex: key,
        key: key,
        render: render,
        ...props,
      };
  };

  const OperatorSelect = ({...props}) => {
    return (
      <Select allowClear style={{width: '100%'}} {...props}>
        {operators.map((operator) => (
          <Select.Option key={operator.id} value={operator.id}>
            {operator.shortName}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const SourceSelect = ({...props}) => {
    return (
      <Select {...props}>
        {SOURCE_TYPE.map((source) => (
          <Select.Option key={source.value} value={source.value}>
            {source.name}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const documentHaveSecondDocDate = (record) => record.secondaryDate || dayjs(record.secondaryDate).isBefore(dayjs());

  const mintrudTimeString = (date) => {
    if (dayjs(date, 'DD.MM.YYYY HH:mm').isValid()) {
      return dayjs(date, 'DD.MM.YYYY HH:mm').format('DD.MM.YYYY HH:mm');
    } else if (dayjs(date).isValid()) {
      return dayjs(date).format('DD.MM.YYYY HH:mm');
    } else {
      return date;
    }
  };

  const columns = useMemo(() => {
    return [
      columnsProps(
        'number',
        (number, record) => (
          <Link to={`/registry/students/${record.id}`}>
            {number} {record.isRelationWith ? '🛧' : null}
          </Link>
        ),
        {
          fixed: true,
        }
      ),
      columnsProps('primaryDate', (date) => dayjs(date).format('DD.MM.YYYY')),
      columnsProps(
        'wishes',
        (wishes) =>
          wishes !== null &&
          wishes !== '' &&
          wishes !== ' ' && (
            <Tooltip
              color="grey"
              style={{maxWidth: '200px'}}
              title={
                <Typography
                  ellipsis={{rows: 3, expandable: false, tooltip: {title: wishes, color: 'grey'}}}
                  style={{maxWidth: '150px', whiteSpace: 'wrap'}}>
                  {wishes}
                </Typography>
              }>
              <InfoCircleOutlined />
            </Tooltip>
          ),
        {width: 60}
      ),
      columnsProps('customer', ({id, name}, record) => (
        <Link to={`/customer/${id}`} style={{maxWidth: '100px', whiteSpace: 'wrap', display: 'block'}}>
          {name}
        </Link>
      )),
      columnsProps(
        'note',
        (note, record) => (
          <EditableCell
            maxWidth={600}
            initialValue={{note: note}}
            valueName="note"
            onOk={(value) => changeNote(record.id, value)}
            renderValue={note}>
            <TextArea style={{resize: 'both'}} />
          </EditableCell>
        ),
        {width: 100}
      ),
      columnsProps('operator', (regOperator, record) => {
        const operatorId = regOperator?.id ? regOperator.id : null;
        const operatorName = regOperator?.name ? regOperator.name : REGISTRY.NOT_APOINTED;

        if (canChangeOperators) {
          return (
            <EditableCell
              initialValue={{id: operatorId}}
              valueName="id"
              onOk={changeOperator(record.id)}
              renderValue={operatorName}>
              <OperatorSelect />
            </EditableCell>
          );
        } else if (!regOperator && adminInfo.isOperator && adminInfo.isAvailable) {
          return (
            <PopConfirmButton
              title="Забрать договор себе?"
              button="Забрать себе"
              okText="Да"
              cancelText="Нет"
              onClick={() => changeOperator(record.id)({id: adminInfo.id})}
              isLoading={loading}
            />
          );
        }
        return <Text>{operatorName}</Text>;
      }),
      columnsProps(
        'source',
        (source, record) => (
          <EditableCell
            initialValue={source}
            valueName={'id'}
            propName="defaultValue"
            onOk={(value) => changeSource(record.id, value)}
            renderValue={source.name}>
            <SourceSelect />
          </EditableCell>
        ),
        {width: 110}
      ),
      columnsProps('postAddress', (postAddress, {customer}) => (
        <Typography.Paragraph style={{maxWidth: '150px', minWidth: '50px', whiteSpace: 'wrap', marginBottom: 0}}>
          {customer.postAddress}
        </Typography.Paragraph>
      )),
      columnsProps('studentTrainings', null, {width: 90}),
      columnsProps('promocode', (promocode) => promocode?.code, {width: 85}),
      columnsProps('isPayed', (paid, {id}) => (
        <Link to={`/registry/payment/${id}`}>{paid ? REGISTRY.PAID : REGISTRY.NOT_PAID}</Link>
      )),
      columnsProps('cost', (sum) => (sum === 0 ? sum : sum.replace('.00', '')), {width: 100}),
      {
        dataIndex: 'costCredit',
        key: 'costCredit',
        title: 'Оплата',
        align: 'center',
        render: (id, record) => record.cost - record.credit,
        width: 75,
      },
      columnsProps('payedDate', (date) => date && dayjs(date).format('DD.MM.YYYY'), {width: 80}),
      columnsProps('credit', (sum) => (sum === 0 ? sum : sum.replace('.00', '')), {width: 53}),
      columnsProps('examResult', (examResult, {id}) => (
        <Link to={`/registry/students/${id}`}>{examResult ? REGISTRY.CIRTIFIED : REGISTRY.NOT_CIRTIFIED}</Link>
      )),
      columnsProps('secondaryActDate', (date) => date && dayjs(date).format('DD.MM.YYYY'), {width: 100}),
      columnsProps('delivery', (delivery, {id}) => <Link to={`/registry/delivery/${id}`}>{delivery}</Link>),
      columnsProps(
        'programs',
        (programs, {customer}) => {
          const width = 70 * Math.ceil(programs.length / 5);
          return (
            <Space align="center">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  gap: 6,
                  maxHeight: 150,
                  width,
                }}>
                {programs.map((program) => (
                  <Tooltip
                    key={program.id}
                    color="grey"
                    style={{maxWidth: '200px'}}
                    title={
                      <Space direction="vertical" align="center" style={{color: 'white', textAlign: 'center'}}>
                        <Text style={{color: 'white'}}>{program.text}</Text>
                      </Space>
                    }>
                    <Typography.Text>{program.name}</Typography.Text>
                  </Tooltip>
                ))}
              </div>
            </Space>
          );
        },
        {width: 105}
      ),
      columnsProps(
        'print',
        (note, record) => {
          if (!documentHaveSecondDocDate(record)) {
            return null;
          } else {
            return (
              <Space direction="vertical">
                <Typography.Link onClick={handleDocumentsMethod(record.id)}>{REGISTRY.DOCUMENTS}</Typography.Link>
                <Space direction="vertical">
                  {folderLoading !== false && folderLoading === record.id ? (
                    <Spin indicator={<LoadingOutlined />} />
                  ) : (
                    <Typography.Link onClick={handleFolderMethod(record.id)}>{REGISTRY.FOLDER}</Typography.Link>
                  )}
                  <Checkbox
                    onChange={(e) => handlePrint(record.id, e.target.checked)}
                    checked={record?.printUpdateDateTime}>
                    {REGISTRY.PRINTED}
                  </Checkbox>
                  {record.isShowMintrud ? (
                    <>
                      <Checkbox
                        onChange={(e) => handleRegistryMintrud(record.id, e.target.checked)}
                        checked={record?.mintrudStatusDateTime}>
                        {REGISTRY.MINTRUD_REGISTRY}
                      </Checkbox>
                      <Typography.Text>
                        {record?.mintrudStatusDateTime ? mintrudTimeString(record?.mintrudStatusDateTime) : null}
                      </Typography.Text>
                    </>
                  ) : null}
                </Space>
              </Space>
            );
          }
        },
        {width: 140}
      ),
      columnsProps(
        'postDataReport',
        (postDataReport, record) => {
          if (record.delivery === 'Экспресс доставка') {
            return null;
          } else if (record.trackNumber) {
            return (
              <Space direction="vertical">
                <Text>{record.trackNumber.admin}</Text>
                <Text>{record.trackNumber.trackNumberDate}</Text>
                <Space>
                  <Popconfirm
                    okText="Да"
                    cancelText="Нет"
                    title="Удалить трек номер?"
                    onConfirm={() => deleteTrackNumber(record.trackNumber.id, record.id)}>
                    <Switch checked />
                  </Popconfirm>
                  <Text>Отправлено</Text>
                </Space>
                <Checkbox
                  checked={postDataReport?.oneSide}
                  onChange={(e) =>
                    dispatch(RegistyAPI.trackNumbers.sendPostData({id: record.id, data: {oneSide: e.target.checked}}))
                  }>
                  1 сторона
                </Checkbox>
                <PostData id={record.id} postData={postDataReport} />
              </Space>
            );
          } else {
            return (
              <Space direction="vertical">
                <Button type="primary" onClick={() => sendTrackNumber(record.id)}>
                  Отправить
                </Button>
                <Checkbox
                  checked={postDataReport?.oneSide}
                  onChange={(e) =>
                    dispatch(RegistyAPI.trackNumbers.sendPostData({id: record.id, data: {oneSide: e.target.checked}}))
                  }>
                  1 сторона
                </Checkbox>
                <PostData id={record.id} postData={postDataReport} />
              </Space>
            );
          }
        },
        {width: 150}
      ),
      columnsProps('availabilityOfDocuments', (value, record) => {
        return (
          <Space direction="vertical" align="start">
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'baseline'}}>
              <Checkbox
                onChange={(e) => changeAviability(e, record.id, DOCUMENT_TYPES.contract.value)}
                name="contract"
                defaultChecked={value?.contract}>
                {REGISTRY.CONTRACT}
              </Checkbox>
              {value?.contract ? <span>{dayjs(value?.contract).format('DD.MM.YYYY')}</span> : null}
            </div>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'baseline'}}>
              <Checkbox
                onChange={(e) => changeAviability(e, record.id, DOCUMENT_TYPES.act.value)}
                name="act"
                defaultChecked={value?.act}>
                {REGISTRY.ACT}
              </Checkbox>
              {value?.act ? <span>{dayjs(value?.act).format('DD.MM.YYYY')}</span> : null}
            </div>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'baseline'}}>
              <Checkbox
                onChange={(e) => changeAviability(e, record.id, DOCUMENT_TYPES.protocol.value)}
                name="protocol"
                defaultChecked={value?.protocol}>
                {REGISTRY.PROTOCOOL}
              </Checkbox>
              {value?.protocol ? <span>{dayjs(value?.protocol).format('DD.MM.YYYY')}</span> : null}
            </div>
          </Space>
        );
      }),
      {
        dataIndex: 'id',
        key: 'buttons',
        align: 'center',
        render: (id, record) => {
          return !record.inArchiveDate ? (
            <Button onClick={() => setArchived(id)}>{REGISTRY.MAKE_ARCHIVED}</Button>
          ) : (
            <Space direction="vertical">
              <Button onClick={() => setActive(id)}>{REGISTRY.MAKE_ACTIVE}</Button>
              <Popconfirm
                okText={LOCALE.SYSTEM.ACCEPT}
                cancelText={LOCALE.SYSTEM.CANCEL}
                title={MESSAGES.CONFIRM}
                onConfirm={() => onDelete(id)}>
                <Button danger type="primary" style={{width: '100%'}}>
                  {LOCALE.SYSTEM.DELETE}
                </Button>
              </Popconfirm>
            </Space>
          );
        },
      },
      columnsProps('userEmailForLoginAs', (email) => (
        <Typography.Link onClick={loginAsUser(dispatch, email)}>Личный кабинет</Typography.Link>
      )),
    ];
  }, [operators, registryColumns, folderLoading]);

  const handleFolderMethod = (id) => () => {
    setFolderLoading(id);
    dispatch(AdminAPI.documents.getFolderDocuments(id))
      .then(({payload}) => {
        setFolderLoading(false);
        window.open(payload.data.redirectUrl, '_parent');
      })
      .catch(() => {
        setFolderLoading(false);
      });
  };

  const handleDocumentsMethod = (id) => () => {
    dispatch(AdminAPI.documents.getDocumentsZIP(id))
      .then(({payload}) => {
        let fileName = payload.headers['content-disposition'].split(';')[1].trim().split('=')[1];
        fileName = fileName.substring(1, fileName.length);

        const link = document.createElement('a');
        link.href = URL.createObjectURL(payload.data);
        link.download = fileName;
        link.click();
        link.remove();
      })
      .catch((err) => showError(err.message));
  };

  const handlePrint = (id, value) => {
    dispatch(RegistyAPI.print({id, data: {status: value}}))
      .then((res) => dispatch(RegistyAPI.color.get(id)))
      .catch((err) => showError());
  };

  const handleRegistryMintrud = (id, value) => {
    dispatch(RegistyAPI.registryMintrud({id, data: {status: value}}))
      .then((res) => res)
      .catch((err) => showError());
  };

  const setActive = (id) => {
    dispatch(RegistyAPI.registry.inArchive({id, data: {status: false}})).then(() => fetchContracts());
  };
  const setArchived = (id) => {
    dispatch(RegistyAPI.registry.inArchive({id, data: {status: true}})).then(() => fetchContracts());
  };

  const changeNote = (id, values) => {
    dispatch(RegistyAPI.note.update({id, note: values}))
      .then(() => showSuccess())
      .catch((err) => showError(err));
  };

  const changeSource = (id, values) => {
    dispatch(RegistyAPI.source.update({id: id, sourceTypeId: values.id}))
      .then(() => showSuccess())
      .catch((err) => showError(err));
  };

  const changeOperator = (id) => (values) => {
    console.log(values);
    dispatch(
      RegistyAPI.operator.set({
        id: id,
        operatorId: values.id === null || values.id === undefined || values.id === 'notApointed' ? null : values.id,
      })
    )
      .then(() => {
        fetchContracts();
      })
      .catch((err) => showError(err));
  };

  // const changeAdditionalPostData = (id, values) => {
  //   dispatch(RegistyAPI.registry.postData({id, data: values}))
  //     .then(() => message.success('Успех'))
  //     .catch((e) => {
  //       message.error(e.message);
  //     });
  // };
  // const changePostData = (e, id) => {
  //   const key = e.target.value;
  //   const checked = e.target.checked;
  //   dispatch(RegistyAPI.registry.postData({id, data: {[key]: checked}}))
  //     .then(() => message.success('Успех'))
  //     .catch((e) => {
  //       message.error(e.message);
  //     });
  // };

  const changeAviability = (e, id, value) => {
    const status = e.target.checked;
    dispatch(RegistyAPI.registry.availabilityOfDocuments({id, data: {documentTypeId: value, status: status}}))
      .then(() => showSuccess())
      .catch((e) => {
        showError(e.message);
      });
  };

  const sendTrackNumber = (id) => {
    dispatch(RegistyAPI.trackNumbers.create({id}))
      .then(() => dispatch(RegistyAPI.color.get(id)))
      .catch((e) => showError(e));
  };

  const deleteTrackNumber = (id, contractId) => {
    dispatch(RegistyAPI.trackNumbers.delete({id}))
      .then(() => dispatch(RegistyAPI.color.get(contractId)))
      .catch((e) => showError(e));
  };

  const onDelete = (id) => {
    dispatch(RegistyAPI.registry.delete(id))
      .then(() => {
        fetchContracts();
        showSuccess('Успех');
      })
      .catch((e) => showError('Ошибка ' + e));
  };

  const clearedColumns = useMemo(() => {
    return columns.filter((val, index) => val !== undefined);
  }, [columns]);

  return (
    <Table
      scroll={{x: 'max-content'}}
      pagination={{
        showSizeChanger: true,
        total: totalCount,
        current: Number(filters.page ?? 1),
        pageSize: pageSize,
        pageSizeOptions: [10, 20, 50, 100, 250, 500],
        onChange: (page, limit) => {
          localStorage.setItem('registryFiltersPagination', JSON.stringify({page, limit, offset: (page - 1) * limit}));
          setSearchParams({...filters, page, limit, offset: (page - 1) * limit}, {replace: true});
        },
        onShowSizeChange: (page, limit) => {
          localStorage.setItem('registryFiltersPagination', JSON.stringify({page, limit, offset: (page - 1) * limit}));
          setSearchParams({...filters, page, limit, offset: (page - 1) * limit}, {replace: true});
        },
      }}
      data-testid="registry-table"
      loading={loading}
      columns={clearedColumns}
      dataSource={contracts}
      sticky
      size="small"
      bordered
      rowKey="id"
      className="table_registry black_bordered"
      rowClassName={(record, index) => {
        if (record.color === '#c9c') {
          return 'purple';
        } else if (record.color === '#9c9') {
          return 'green';
        } else {
          return record.color;
        }
      }}
    />
  );
};

export default RegistryTable;
