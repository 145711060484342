import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Col, Row, Space, Typography} from 'antd';

import {CUSTOMER_TYPES} from '../../common';
import {loginAsUser} from '../../utils/logInAsUser';
import styles from './CustomerInfoLink.module.css';

const CustomerInfoLink = ({customer}) => {
  const dispatch = useDispatch();
  let tooltipTitle = '';

  const {customerTypeId, customerTypeName, organizationName, surname, firstname} = customer;

  if (customerTypeId === CUSTOMER_TYPES[0].id) {
    tooltipTitle = (
      <Link to={`/customer/${customer.id}`} component={Typography.Link}>
        <Typography.Paragraph className={styles.customer_link}>{organizationName}</Typography.Paragraph>
      </Link>
    );
  } else if (customerTypeId === CUSTOMER_TYPES[1].id) {
    tooltipTitle = (
      <Link to={`/customer/${customer.id}`} component={Typography.Link}>
        <Typography.Paragraph className={styles.customer_link}>
          {surname} {firstname}
        </Typography.Paragraph>
      </Link>
    );
  } else {
    tooltipTitle = (
      <Link to={`/customer/${customer.id}`} component={Typography.Link}>
        <Typography.Paragraph className={styles.customer_link}>{organizationName}</Typography.Paragraph>
      </Link>
    );
  }

  return (
    <Space wrap size="small" style={{marginBottom: '15px'}}>
      <Col>
        <Row>{tooltipTitle}</Row>
        <Row>
          {customer.contracts.map((contract) => (
            <Link
              key={contract.id}
              to={`/registry/students/${contract.id}`}
              component={Typography.Link}
              style={contract?.isPayedOnline === true ? {textDecoration: 'underline'} : undefined}
              className="link">
              {contract.number}
              {contract.inArchiveDate ? ' (арх.)' : null}
              &nbsp;&nbsp;
            </Link>
          ))}

          <span>&nbsp;&nbsp;</span>
          <Typography.Link onClick={loginAsUser(dispatch, customer.userEmail)} className="link">
            (Личный кабинет)
          </Typography.Link>
        </Row>
      </Col>
      {/* <Tooltip title={tooltipTitle}>
        <Button icon={<InfoCircleOutlined />} type="link" style={{color: '#222'}} />
      </Tooltip> */}
    </Space>
  );
};

export default CustomerInfoLink;
