import {Button, Form, Input} from 'antd';
import ModalForm from '../UX/ModalForm';
import StringDatePicker from '../UX/StringDatePicker';
import {useDispatch} from 'react-redux';
import {AdminAPI} from '../../store/AdminStore/methods';
import UseMessage from '../hooks/useMessage';
import dayjs from 'dayjs';

const layout = {
  labelCol: {
    sm: {span: 8},
    xl: {
      span: 8,
    },
  },
  // wrapperCol: {
  //   xl: {
  //     span: 8,
  //   },
  // },
};

const initialValues = {
  amount: null,
  activateDateTime: null,
  note: null,
};

export const AddBonusesForm = ({userId}) => {
  const dispatch = useDispatch();
  const {showError, showSuccess} = UseMessage();
  const FormItems = (
    <>
      <Form.Item required rules={[{required: true}]} label="Количество бонусов" name="amount">
        <Input placeholder={0} />
      </Form.Item>
      <Form.Item label="Дата активации" name="activateDateTime">
        <StringDatePicker format={'DD.MM.YYYY'} placeholder={'дд.мм.гггг'} />
      </Form.Item>
      <Form.Item required rules={[{required: true}]} label="Комментарий" name="note">
        <Input.TextArea />
      </Form.Item>
    </>
  );

  const handleSummbit = (data) => {
    dispatch(
      AdminAPI.users.addBonuses({
        ...data,
        activateDateTime: data.activateDateTime !== null ? dayjs(data.activateDateTime).format('YYYY-MM-DD') : null,
        userId,
      })
    )
      .then((response) => {
        showSuccess();
        dispatch(AdminAPI.users.getById({id: userId, ext: true}));
      })
      .catch((e) => showError('Ошибка при добавлении бонусов'));
  };

  return (
    <ModalForm
      title="Добавление бонусов"
      initialValues={initialValues}
      formItems={FormItems}
      onOk={handleSummbit}
      modalProps={{width: 550, centered: true}}
      formProps={{
        layout: layout,
      }}>
      <Button onClick={() => {}}>Добавить бонусы</Button>
    </ModalForm>
  );
};
