import {Badge, Button, List, Space, Spin, Table, Tooltip, Typography} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';

import {LOCALE, paramsToObject} from '../../common';
import {QuestionsSelectors} from '../../store/QuestionsStore';
import {QuestionsAPI} from '../../store/QuestionsStore/methods';
import UseMessage from '../hooks/useMessage';
import ModalForm from '../UX/ModalForm';
import NewQuestionForm from './NewQuestionForm';
import NormsActuality from '../SystemContentControl/NormsActuality';

const {QUESTIONS} = LOCALE;

const QuestionsTable = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const loading = useSelector(QuestionsSelectors.loading);
  const isNormsLoading = useSelector(QuestionsSelectors.isNormsLoading);
  const questions = useSelector(QuestionsSelectors.questions);
  const questionsTableCount = useSelector(QuestionsSelectors.questionsTableCount);

  const filters = paramsToObject(searchParams);

  const {showError, showSuccess} = UseMessage();

  const deleteHandler = (id) => {
    dispatch(QuestionsAPI.delete(id))
      .then(() => showSuccess('Вопрос успешно удален'))
      .catch((e) => showError(e));
  };

  const createHandler = (values) => {
    dispatch(QuestionsAPI.create(values))
      .then((response) => {
        navigate(`/questions/${response.payload.id}`);
        showSuccess();
      })
      .catch((e) => showError(e));
  };

  const columns = [
    {
      title: QUESTIONS.QUESTION_TEXT,
      dataIndex: 'text',
      key: 'id',
      width: 200,
      render: (text, record) => {
        const parser = new DOMParser();
        const pText = parser.parseFromString(text, 'text/html').body.textContent;
        return <Typography.Paragraph style={{textAlign: 'justify'}}>{pText}</Typography.Paragraph>;
      },
    },
    {
      dataIndex: 'id',
      key: 'id',
      width: 50,
      title: () => (
        <ModalForm
          modalProps={{width: 700, centered: true}}
          formProps={{align: 'vertical'}}
          onOk={createHandler}
          formItems={<NewQuestionForm />}
          title={QUESTIONS.NEW_QUESTION}>
          <Button type="primary" icon={<PlusOutlined />} />
        </ModalForm>
      ),
      align: 'end',
      render: (id, record) => {
        return (
          <Space>
            {isNormsLoading ? (
              <Spin />
            ) : (
              <NormsActuality contentExists={record.contentExists} isAllNormsActual={record.isAllNormsActual} />
            )}
            <Button icon={<EditOutlined />} onClick={() => navigate(`/questions/${id}`)} />
            <Button danger icon={<DeleteOutlined />} onClick={() => deleteHandler(id)} />
          </Space>
        );
      },
    },
  ];

  return (
    <Table
      rowKey={'id'}
      tableLayout="fixed"
      dataSource={questions}
      columns={columns}
      loading={loading}
      pagination={{
        total: questionsTableCount,
        current: Number(filters.page ?? 1),
        pageSize: Number(filters.limit ?? 10),
        onChange: (page, limit) =>
          setSearchParams({...filters, page, limit, offset: (page - 1) * limit}, {replace: true}),
        onShowSizeChange: (page, limit) =>
          setSearchParams({...filters, page, limit, offset: (page - 1) * limit}, {replace: true}),
        pageSizeOptions: [10, 20, 50, 100, 250, 500],
      }}
    />
  );
};

export default QuestionsTable;
