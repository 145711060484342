import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Popconfirm, Space, Table} from 'antd';
import dayjs from 'dayjs';

import {compare, LOCALE, paramsToObject, PROMOCODE_TYPES} from '../../common';
import {AdminSelectors} from '../../store/AdminStore';
import {AdminAPI} from '../../store/AdminStore/methods';
import UseMessage from '../hooks/useMessage';
import ModalForm from '../UX/ModalForm';
import PromocodeForm from './PromocodeForm';

const {PROMOCODES, DATES, COMMON} = LOCALE;
const PromocodesTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const filters = paramsToObject(searchParams);

  const promocodes = useSelector(AdminSelectors.promocodes);
  const promocodesTotalCount = useSelector(AdminSelectors.promocodesTotalCount);
  const loading = useSelector(AdminSelectors.loading);
  const dispatch = useDispatch();

  const {showError, showSuccess} = UseMessage();
  const newPromocode = {
    startDate: dayjs(),
    promocodeTypeId: PROMOCODE_TYPES[0].value,
  };

  const createPromocode = (values) => {
    dispatch(AdminAPI.promocodes.create(values))
      .then(() => {
        showSuccess();
        dispatch(AdminAPI.promocodes.getAll(filters));
      })
      .catch((e) => showError(e));
  };

  const updatePromocode = (id, values) => {
    const keys = Object.keys(values);
    keys.map((key) => values[key] === null && delete values[key]);

    dispatch(
      AdminAPI.promocodes.update({
        id,
        data: {...values, discount: values.discount && parseInt(values.discount)},
      })
    )
      .then(() => {
        showSuccess();
        dispatch(AdminAPI.promocodes.getAll(filters));
      })
      .catch((e) => showError(e));
  };

  const deletePromocode = (id) => {
    dispatch(AdminAPI.promocodes.delete(id))
      .then(() => showSuccess('Промокод успешно удален'))
      .catch((err) => showError(err));
  };

  let columns = [
    {
      title: PROMOCODES.CODE,
      align: 'center',
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => compare(a.code, b.code),
    },
    {
      title: PROMOCODES.PROMOCODE_TYPE,
      align: 'center',
      dataIndex: 'promocodeTypeName',
      key: 'promocodeTypeName',
      sorter: (a, b) => compare(a.promocodeTypeName, b.promocodeTypeName),
    },
    {
      title: DATES.START_ACTION,
      align: 'center',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (date) => date && dayjs(date).format('D MMMM YYYY'),
    },
    {
      title: DATES.END_ACTION,
      align: 'center',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (date) => date && dayjs(date).format('D MMMM YYYY'),
    },
    {
      title: COMMON.DISCOUNT,
      align: 'center',
      dataIndex: 'discount',
      key: 'discount',
      render: (value, record) => {
        if (record.promocodeTypeId === PROMOCODE_TYPES[2].value) return record?.pricesWithTrainingProgramTypes || '-';
        else return value && parseInt(value) + ' ₽';
      },
    },
    {
      title: COMMON.NOTE,
      align: 'center',
      dataIndex: 'note',
      key: 'note',
    },
    {
      dataIndex: 'id',
      align: 'center',
      title: () => {
        return (
          <ModalForm
            title={PROMOCODES.NEW_PROMOCODE}
            initialValues={newPromocode}
            modalProps={{destroyOnClose: true}}
            onOk={createPromocode}
            formItems={<PromocodeForm />}>
            <Button icon={<PlusOutlined />} type="primary" />
          </ModalForm>
        );
      },
      key: 'buttons',
      render: (id, record) => {
        const initialValues = {
          ...record,
          startDate: record.startDate ? dayjs(record.startDate).format('DD.MM.YYYY') : null,
          endDate: record.endDate ? dayjs(record.endDate).format('DD.MM.YYYY') : null,
        };

        return (
          <Space>
            {record.promocodeTypeId === PROMOCODE_TYPES[2].value ? (
              <Button onClick={() => navigate(`/promocodes/${id}`)} icon={<EditOutlined />} />
            ) : (
              <ModalForm
                title={PROMOCODES.REDACT_PROMOCODE}
                modalProps={{destroyOnClose: true}}
                onOk={(values) => updatePromocode(id, values)}
                initialValues={initialValues}
                formItems={<PromocodeForm redact={true} />}>
                <Button icon={<EditOutlined />} />
              </ModalForm>
            )}
            <Popconfirm
              title="Удалить промокод?"
              okButtonProps={{loading: loading}}
              onConfirm={() => deletePromocode(record.id)}>
              <Button type="danger" icon={<DeleteOutlined />} />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      bordered
      pagination={{
        showSizeChanger: true,
        current: Number(filters.page ?? 1),
        pageSize: Number(filters.limit ?? 10),
        total: promocodesTotalCount,
        pageSizeOptions: [10, 20, 50, 100],
        onChange: (page, limit) => {
          localStorage.setItem('promocodesFilters', JSON.stringify({page, limit, offset: (page - 1) * limit}));
          setSearchParams({...filters, page, limit, offset: (page - 1) * limit}, {replace: true});
        },
        onShowSizeChange: (page, limit) => {
          localStorage.setItem('promocodesFilters', JSON.stringify({page, limit, offset: (page - 1) * limit}));
          setSearchParams({...filters, page, limit, offset: (page - 1) * limit}, {replace: true});
        },
      }}
      rowKey="id"
      size={'middle'}
      scroll={{x: 'max-content'}}
      loading={loading}
      dataSource={promocodes}
    />
  );
};

export default PromocodesTable;
