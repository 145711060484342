import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {LoadingOutlined} from '@ant-design/icons';
import {Layout, Spin} from 'antd';

import {AdminSelectors} from './store/AdminStore';
import {AdminAPI} from './store/AdminStore/methods';
import {RegistyAPI} from './store/RegistryStore/methods';

import Navigation from './components/Layout/Navigation';
import PageHeader from './components/Layout/PageHeader';
import Router from './components/Router';

const {Content, Header, Sider} = Layout;

const fixed = {overflow: 'auto', height: '100vh', position: 'sticky', left: 0, top: 0, bottom: 0};

const defaultCollapsed = localStorage.getItem('sider') ? localStorage.getItem('sider') : 0;

const App = () => {
  const dispatch = useDispatch();
  const checkLoading = useSelector(AdminSelectors.checkLoading);
  const [searchParams, setSearchParams] = useSearchParams();
  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  document.title = process.env.REACT_APP_SITE_TITLE;

  const fetchCount = () => {
    // dispatch(AdminAPI.customers.getCountByFilter({userChangeQueryActive: 'queryActive'}));
    // dispatch(RegistyAPI.registry.getCountContractByFilter({isNeedDiplomCheck: true}));
    // dispatch(RegistyAPI.registry.getCountContractByFilter({isStudentChangeQueryActive: true}));
  };

  const checkAccess = () => {
    const accessToken = searchParams.get('accessToken');
    accessToken && localStorage.setItem('accessToken', accessToken);

    dispatch(AdminAPI.auth.checkAccess()).then(() => {
      if (accessToken) {
        searchParams.delete('accessToken');
        searchParams.delete('accountId');
        setSearchParams(searchParams);
      }
    });
  };

  const onChangeSider = (value) => {
    setCollapsed(value);
    localStorage.setItem('sider', value);
  };

  useEffect(() => {
    checkAccess();
    // fetchCount();
    // const interval = setInterval(() => {
    //   fetchCount();
    // }, 60000);
    // return () => {
    //   clearInterval(interval);
    // };
  }, []);

  if (checkLoading)
    return (
      <div className="fullpage-loader">
        <Spin indicator={<LoadingOutlined style={{fontSize: '64px'}} />} />
      </div>
    );
  else
    return (
      <Layout style={{height: '100%'}}>
        <Header style={{maxHeight: '50px', borderBottom: '1px solid #1890ff'}}>
          <PageHeader />
        </Header>
        <Layout style={{height: '100%'}} hasSider>
          <Sider style={fixed} width={250} collapsible collapsed={collapsed} onCollapse={onChangeSider}>
            <Navigation collapsed={collapsed} />
          </Sider>
          <Content style={{padding: '10px 30px'}}>
            <Router />
          </Content>
        </Layout>
      </Layout>
    );
};

export default App;
