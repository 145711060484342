import {createAsyncThunk} from '@reduxjs/toolkit';
import {http} from '../../common';

export const PublicAPI = {
  suggestAutocomplete: createAsyncThunk(
    'suggest-options',
    async (data) => await http.post('public/dadata/suggest', data)
  ),
  companySettings: createAsyncThunk('company-settings', async () => {
    return (await http.get('public/company-settings')).data;
  }),
};
