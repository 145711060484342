import {useSelector} from 'react-redux';
import {Link, useSearchParams} from 'react-router-dom';
import {Button, Table} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';

import {AdminSelectors} from '../../store/AdminStore';
import {compare, LOCALE, paramsToObject} from '../../common';
import {ROUTES} from '../../common/routes';

const {COMMON} = LOCALE;

const UsersTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filters = paramsToObject(searchParams);

  const users = useSelector(AdminSelectors.users);
  const loading = useSelector(AdminSelectors.loading);
  const usersTotalCount = useSelector(AdminSelectors.usersTotalCount);

  const columns = [
    {
      title: COMMON.SURNAME,
      align: 'center',
      dataIndex: 'surname',
      key: 'surname',
      sorter: (a, b) => compare(a.surname, b.surname),
    },
    {
      title: COMMON.NAME,
      align: 'center',
      dataIndex: 'firstname',
      key: 'firstname',
    },
    {
      title: COMMON.SECOND_NAME,
      align: 'center',
      dataIndex: 'secondname',
      key: 'secondname',
    },
    {
      title: COMMON.EMAIL,
      align: 'center',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => compare(a.email, b.email),
    },
    {
      title: COMMON.PHONE,
      align: 'center',
      dataIndex: 'phone',
      key: 'phone',
    },

    {
      dataIndex: 'id',
      key: 'buttons',
      align: 'center',
      render: (id) => {
        return (
          <Link to={`/users/${id}`}>
            <Button icon={<InfoCircleOutlined />} />
          </Link>
        );
      },
    },
  ];

  return (
    <Table
      pagination={{
        size: 'small',
        total: usersTotalCount,
        current: Number(filters.page ?? 1),
        pageSize: Number(filters.limit ?? 10),
        onChange: (page, limit) =>
          setSearchParams({...filters, page, limit, offset: (page - 1) * limit}, {replace: true}),
        onShowSizeChange: (page, limit) =>
          setSearchParams({...filters, page, limit, offset: (page - 1) * limit}, {replace: true}),
        pageSizeOptions: [10, 20, 50, 100, 250, 500],
      }}
      rowKey="id"
      bordered
      scroll={{x: 'auto'}}
      loading={loading}
      dataSource={users}
      columns={columns}
    />
  );
};

export default UsersTable;
