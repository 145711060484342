import {TEXT} from './ru_RU';
import axios from 'axios';
import {API_URL, AUTH_URL} from './constants';

export const LOCALE = TEXT;
export * from './constants';
//
//
//

export const http = axios.create({
  baseURL: API_URL,
});

http.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem('accessToken');

  config.headers = {
    ...config.headers,
    'Content-Type': 'application/json',
  };

  if (accessToken) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  return config;
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      document.location.replace(process.env.REACT_APP_AUTH_URL);
    }
    return Promise.reject(error);
  }
);

export const paramsToObject = (entries) => {
  const result = {};
  for (const [key, value] of entries) {
    if (value === 'true' || value === 'false') {
      result[key] = JSON.parse(value);
    } else if (value === 'null' || value === 'undefined') {
      result[key] = JSON.parse(value);
    } else {
      result[key] = value;
    }
  }
  return result;
};

export const removeEmptyFields = (data) => {
  Object.keys(data).forEach((key) => {
    if (data[key] instanceof Object) {
      removeEmptyFields(data[key]);
    }
    if (data[key] === '' || data[key] == null) {
      delete data[key];
    }
  });
  return data;
};

export const defaultRegistryColumns = {
  number: true,
  primaryDate: true,
  wishes: true,
  customer: true,
  note: true,
  operator: true,
  source: true,
  postAddress: true,
  studentTrainings: true,
  cost: true,
  payedDate: true,
  credit: true,
  promocode: true,
  isPayed: true,
  examResult: true,
  secondaryActDate: true,
  delivery: true,
  programs: true,
  print: true,
  postDataReport: true,
  availabilityOfDocuments: true,
  userEmailForLoginAs: true,
};

export const AuthUrlWithReturnUrl = (href = document.location) => {
  const url = new URL(AUTH_URL);
  url.searchParams.append('returnUrl', href);
  return url;
};

export const compare = (a, b) => {
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};

export const JoditConfig = {
  theme: JSON.parse(localStorage.getItem('theme')),
  disablePlugins:
    'about,add-new-line,backspace,class-span,clean-html,clipboard,color,copy-format,enter,error-messages,file,focus,font,format-block,hotkeys,fullsize,hr,iframe,inline-popup,indent,justify,key-arrow-outside,limit,line-height,link,media,mobile,ordered-list,paste,paste-storage,paste-from-word,placeholder,powered-by-jodit,preview,print,redo-undo,resize-cells,search,select,source,select-cells,speech-recognize,spellcheck,sticky,table,symbols,tab,table-keyboard-navigation,tooltip,video,wrap-nodes,xpath',
  buttons: ['bold', 'italic', 'underline', 'image'],
  uploader: {insertImageAsBase64URI: true},
  imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
};
