import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Button, List, Space, Spin, Table, Typography} from 'antd';

import {AdminAPI} from '../../store/AdminStore/methods';
import {AdminSelectors} from '../../store/AdminStore';

import {LOCALE} from '../../common';
import PageLayout from '../../components/Layout/PageLayout';
import UseMessage from '../../components/hooks/useMessage';

const CommercialProposeInfo = () => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const commercialProposeInfo = useSelector(AdminSelectors.commercialProposeInfo);
  const loading = useSelector(AdminSelectors.loading);

  const {showError} = UseMessage();

  useEffect(() => {
    dispatch(AdminAPI.commercialProposes.getById(id));
  }, []);

  const renderOffer = () => {
    dispatch(AdminAPI.commercialProposes.render(id))
      .then((res) => {
        const url = URL.createObjectURL(res.payload.data);
        window.open(url);
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => showError(err));
  };

  const tableData = commercialProposeInfo.trainingProgramTypes.map((trainingProgramType) => {
    const data = {
      key: trainingProgramType.id,
      name: `(${trainingProgramType.name}) ${trainingProgramType.fullName}`,
    };

    const prices = commercialProposeInfo.trainingProgramTypePrices?.find(
      (price) => price.trainingProgramTypeId === trainingProgramType.id
    ).prices;
    prices?.forEach((price) => (data[price.peopleCount] = price.cost));

    return data;
  });

  return (
    <PageLayout pageTitle={LOCALE.PAGE_TITLES.COMMERCIAL_PROPOSE_INFO}>
      {loading ? (
        <div style={{margin: 'auto', marginTop: '30%', textAlign: 'center'}}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Space>
            <Typography.Text strong style={{fontSize: 16}}>
              Email:
            </Typography.Text>
            <Typography.Text>{commercialProposeInfo.email}</Typography.Text>
          </Space>
          <Space>
            <Typography.Text strong style={{fontSize: 16}}>
              Название организации:
            </Typography.Text>
            <Typography.Text>{commercialProposeInfo.companyName}</Typography.Text>
          </Space>
          <Button type="primary" onClick={renderOffer}>
            Показать оригинал
          </Button>
          <Table bordered rowKey="key" dataSource={tableData} loading={loading} pagination={false}>
            <Table.Column title="Программы обучения" dataIndex="name" align="center" />
            <Table.Column
              title="Дистанционное обучение, руб/чел в зависимости от количества слушателей или количества выбранных курсов"
              align="center">
              <Table.Column title="1+" dataIndex="0" align="center" />
              <Table.Column title="5+" dataIndex="5" align="center" />
              <Table.Column title="10+" dataIndex="10" align="center" />
              <Table.Column title="20+" dataIndex="20" align="center" />
              <Table.Column title="30+" dataIndex="30" align="center" />
              <Table.Column title="50+" dataIndex="50" align="center" />
            </Table.Column>
          </Table>
        </>
      )}
    </PageLayout>
  );
};

export default CommercialProposeInfo;
