import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {EditOutlined, InfoCircleOutlined} from '@ant-design/icons';
import {Button, Card, Checkbox, Col, Form, Input, Modal, Row, Space, Tag, Tooltip, Typography} from 'antd';
import {MaskedInput} from 'antd-mask-input';

import UseMessage from '../hooks/useMessage';
import CustomForm from '../UX/CustomForm';

import {ADMIN_RIGHTS, LOCALE} from '../../common';
import {AdminAPI} from '../../store/AdminStore/methods';
import CardItem from './Item';
import {Link} from 'react-router-dom';
import CustomerInfoLink from './CustomerInfoLink';
import {RegistyAPI} from '../../store/RegistryStore/methods';
import {AddBonusesForm} from '../Bonuses/addBonusesForm';
import {AdminSelectors} from '../../store/AdminStore';
import ModalForm from '../UX/ModalForm';

const {Item} = Form;
const {USER, COMMON, PLACEHOLDERS, SYSTEM, DATES} = LOCALE;

const UserCard = ({data, loading, id}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const adminInfo = useSelector(AdminSelectors.adminInfo);
  const displayBonusesButton = adminInfo.adminRights.includes(ADMIN_RIGHTS.bonusesAccrual.id);

  const {showSuccess, showError} = UseMessage();

  const [passwordChangeLoading, setPasswordChangeLoading] = useState(false);
  const [newPassword, setNewPassword] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const TitleWithIcon = () => {
    return (
      <Row justify="space-between" align="middle">
        {USER.MAIN_INFO}
        <div style={{cursor: 'pointer'}} onClick={toggleDisabled}>
          Редактировать
          <EditOutlined style={{marginLeft: 5}} title={'Редактировать'} />
        </div>
      </Row>
    );
  };

  const handleCancel = () => {
    setDisabled(true);
    form.resetFields();
  };

  const updateUser = (values) => {
    dispatch(AdminAPI.users.update({id, data: values}))
      .then(() => {
        showSuccess();
        setDisabled(true);
      })
      .catch((e) => showError(e));
  };

  const toggleDisabled = () => {
    setDisabled(!disabled);
    if (!disabled) form.resetFields();
  };

  const handleBonusesMovementButton = () => {
    // return dispatch(RegistyAPI.documents.infoBonuses(id))
    //   .then((res) => {
    //     const bonuses = res.payload.data;
    //     Modal.info({
    //       title: 'Движение бонусов',
    //       width: '650px',
    //       closable: true,
    //       maskClosable: true,
    //       content: <BonusesTable bonuses={bonuses} />,
    //     });
    //   })
    //   .catch((e) => showError(e));
  };

  const handleGenetePassword = () => {
    setPasswordChangeLoading(true);
    const crypto = window.crypto || window.msCrypto;
    let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let pwordLength = 10;
    let password = '';
    const array = new Uint32Array(chars.length);
    crypto.getRandomValues(array);

    for (let i = 0; i < pwordLength; i++) {
      password += chars[array[i] % chars.length];
    }
    const data = {
      newPassword: password,
      newPassword_confirmation: password,
    };

    dispatch(AdminAPI.users.changePassword({id, data: data}))
      .then(() => {
        setPasswordChangeLoading(false);
        setNewPassword(password);
        showSuccess('Пароль успешно сгенерирован');
      })
      .catch((e) => showError(e));
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const copyValueToClipboard = (name = 'Пароль', value) => {
    if (value === null || value === undefined) {
      return showError(`${name} отсутсвутет`);
    }
    navigator.clipboard.writeText(value);
    showSuccess(`${name} скопиравн в буфер обмена`);
  };

  const ChangePasswordModalContent = () => {
    return (
      <Space style={{width: '100%'}} direction="vertical" size={'large'} align="center">
        {!newPassword && (
          <Typography style={{textAlign: 'center'}}>
            Пароль данного пользователя будет сгенерирован автоматически заново. Изменить пароль пользователю на новый?
          </Typography>
        )}
        <Space>
          {!newPassword ? (
            <>
              <Button
                disabled={passwordChangeLoading}
                loading={passwordChangeLoading}
                onClick={handleGenetePassword}
                danger
                type="primary">
                {SYSTEM.YES}
              </Button>
              <Button disabled={passwordChangeLoading} loading={passwordChangeLoading} onClick={handleCloseModal}>
                {SYSTEM.NO}
              </Button>
            </>
          ) : (
            <Space direction="vertical" align="center" size={'large'}>
              <Space>
                <Typography>Новый пароль: {newPassword}</Typography>
                <Tag
                  onClick={() => {
                    copyValueToClipboard('Пароль', newPassword);
                  }}
                  style={{cursor: 'pointer'}}>
                  Копировать
                </Tag>
              </Space>
              <Button onClick={handleCloseModal} type="primary">
                Закрыть
              </Button>
            </Space>
          )}
        </Space>
      </Space>
    );
  };

  return (
    <CustomForm
      layout={{labelCol: {span: 12}}}
      formInstance={form}
      onOk={updateUser}
      // disabled={disabled}
      initialValues={data}
      showButtons={false}>
      <Row gutter={[16, 24]}>
        <Col span={12}>
          <Card style={{height: '100%'}} loading={loading} title={<TitleWithIcon />}>
            <CardItem
              name="surname"
              label={COMMON.SURNAME}
              placeholder={PLACEHOLDERS.SURNAME}
              value={data?.surname}
              disabled={disabled}
            />
            <CardItem
              name="firstname"
              label={COMMON.NAME}
              placeholder={PLACEHOLDERS.NAME}
              value={data?.firstname}
              disabled={disabled}
            />
            <CardItem
              name="secondname"
              label={COMMON.SECOND_NAME}
              placeholder={PLACEHOLDERS.SECOND_NAME}
              value={data?.secondname}
              disabled={disabled}
            />
            <CardItem
              name="phone"
              label={COMMON.PHONE}
              placeholder={PLACEHOLDERS.PHONE}
              value={data?.phone}
              disabled={disabled}
              Сomponent={MaskedInput}
              componentProps={{mask: '+{7} (000) 000-00-00'}}
            />
            <CardItem
              name="phoneExt"
              label={COMMON.PHONE_EXT}
              placeholder="308"
              value={data?.phoneExt ? data?.phoneExt : 'Отсутствует'}
              disabled={disabled}
            />
            <CardItem
              name="email"
              label={COMMON.EMAIL}
              placeholder={PLACEHOLDERS.EMAIL}
              value={data?.email}
              disabled={disabled}
            />
            <Form.Item label="Договора" style={{marginTop: 10}}>
              {data?.customers?.length === 0 ? (
                <Typography>Отсутствуют</Typography>
              ) : (
                data?.customers?.map((customer) => <CustomerInfoLink key={customer.id} customer={customer} />)
              )}
            </Form.Item>
            {!disabled && (
              <Row justify="center" gutter={[16, 24]}>
                <Col>
                  <Button onClick={form.submit} type="primary">
                    {SYSTEM.SAVE}
                  </Button>
                </Col>
                <Col>
                  <Button onClick={handleCancel}>{SYSTEM.CANCEL}</Button>
                </Col>
              </Row>
            )}
          </Card>
        </Col>
        <Col span={12}>
          <Row gutter={[16, 24]}>
            <Col span={24}>
              <Card style={{height: '100%'}} loading={loading} title={USER.BONUSES_INFO}>
                <Item labelCol={{span: 16}} name="bonuses" label={COMMON.BONUSES}>
                  <Input bordered={false} readOnly={true} min={0} />
                </Item>
                <Item labelCol={{span: 16}} name="bonusesNotActivated" label={COMMON.BONUSES_INACTIVE}>
                  <Input bordered={false} readOnly={true} min={0} />
                </Item>
                <Item
                  labelCol={{span: 16}}
                  name="bonusesEnabled"
                  label={COMMON.AVAIBLE_BONUSES}
                  valuePropName="checked">
                  <Checkbox disabled={disabled} />
                </Item>
                {displayBonusesButton && (
                  <Row gutter={[16, 24]}>
                    <Col>
                      <Button disabled onClick={handleBonusesMovementButton}>
                        Просмотр информации о движении бонусов
                      </Button>
                    </Col>
                    <Col>
                      <AddBonusesForm userId={id} />
                    </Col>
                  </Row>
                )}
              </Card>
            </Col>
            <Col span={24}>
              <Card style={{height: '100%'}} loading={loading} title={USER.ACTIVITY_INFO}>
                <Item name={'isActive'} labelCol={{span: 16}} label={SYSTEM.ACTIVATED} valuePropName="checked">
                  <Checkbox disabled={disabled} />
                </Item>
                <Item name={'isNoDocs'} labelCol={{span: 16}} label={USER.NO_DOCS} valuePropName="checked">
                  <Checkbox disabled={disabled} />
                </Item>
                <Item name={'lastEnterDate'} labelCol={{span: 16}} label={DATES.LAST_ENTER_DATE}>
                  <Input bordered={false} readOnly={true} />
                </Item>
                <Item name={'lastChangePasswordDate'} labelCol={{span: 16}} label={DATES.LAST_CHANGE_PASSWORD_DATE}>
                  <Input bordered={false} readOnly={true} />
                </Item>
                <Button onClick={handleOpenModal}>{USER.CHANGE_USER_PASSWORD}</Button>
                <Modal
                  onCancel={handleCloseModal}
                  cancelButtonProps={{disabled: passwordChangeLoading}}
                  title={USER.CHANGE_USER_PASSWORD}
                  footer={false}
                  open={modalOpen}
                  maskClosable={false}>
                  <ChangePasswordModalContent />
                </Modal>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </CustomForm>
  );
};

export default UserCard;
