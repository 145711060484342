import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import {
  DeleteOutlined,
  ExclamationOutlined,
  FileExclamationOutlined,
  PlusOutlined,
  SaveFilled,
  SearchOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {Badge, Button, Col, Input, Popconfirm, Row, Select, Space, Table, Tag, Tooltip, Typography} from 'antd';
import dayjs from 'dayjs';

import {CHECK_REASON, compare, LOCALE} from '../../common';

import {RegistySelectors} from '../../store/RegistryStore';
import {RegistyAPI} from '../../store/RegistryStore/methods';

import EditableCell from '../UX/EditableCell';
import ModalForm from '../UX/ModalForm';
import StringDatePicker from '../UX/StringDatePicker';

import NewListenerForm from './NewListenerForm';
import ChangeQueryItems from './ChangeQueryItems';
import NewProgramForm from './NewProgramForm';
import UseMessage from '../hooks/useMessage';
import AddNewProgramsForm from './addNewProgramsForm';
import {AdminAPI} from '../../store/AdminStore/methods';
import {AdminSelectors} from '../../store/AdminStore';
import listenersSurnameSearch from './listenersSurnameSearch.js';
import {NATIONALITY_TYPE} from '../../common/nationality';

const {COMMON, MESSAGES, DOCUMENTS, LISTENERS} = LOCALE;
const {Text} = Typography;

const ListenersTable = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const searchInput = useRef(null);

  const dispatch = useDispatch();
  const listeners = useSelector(RegistySelectors.listeners);
  const programs = useSelector(RegistySelectors.listenerPrograms);
  const loading = useSelector(RegistySelectors.loading);
  const programsLoading = useSelector(RegistySelectors.programsLoading);
  const contractInfo = useSelector(RegistySelectors.contractInfo);
  const adminInfo = useSelector(AdminSelectors.adminInfo);

  const {showError, showSuccess} = UseMessage();

  const handleSurnameSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };
  const handleSurnameSearchReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const fetchListeners = () => {
    dispatch(RegistyAPI.listeners.getAll(id)).catch((e) => {
      showError('Ошибка получения информации');
      navigate('*');
    });
  };

  useEffect(() => {
    dispatch(RegistyAPI.listeners.contractInfo(id));
    fetchListeners();
  }, []);

  const fetchPrograms = () => {
    dispatch(RegistyAPI.listeners.getPrograms());
  };

  const changeStudent = (value, id) => {
    if (value.birthday) {
      value.birthday = dayjs(value.birthday, 'DD.MM.YYYY').isValid()
        ? dayjs(value.birthday, 'DD.MM.YYYY').format('YYYY-MM-DD')
        : dayjs(value.birthday).format('YYYY-MM-DD');
    }

    if (value.SNILS) {
      value.SNILS = value.SNILS.replaceAll(/[-]|\s/g, '');
    }

    dispatch(RegistyAPI.listeners.update({id, data: value}))
      .then(() => {
        fetchListeners();
        showSuccess();
      })
      .catch((e) => showError(e));
  };

  const saveChanges = (id) => {
    dispatch(RegistyAPI.listeners.update({id, data: {changeQueryActive: false}}))
      .then(() => {
        fetchListeners();
        showSuccess();
      })
      .catch((e) => showError(e));
  };

  const addStudent = (values) => {
    const data = {...values};
    delete data.trainingProgramId;

    dispatch(RegistyAPI.listeners.create({id, data}))
      .then((res) => {
        addProgram(res.payload.id)({trainingProgramId: values.trainingProgramId});
        dispatch(RegistyAPI.listeners.getAll(id));
        showSuccess();
      })
      .catch((err) => {
        contractInfo?.contractIsPayed
          ? showError('Внимание! Договор оплачен, для изменения списка слушателей необходимо снять оплату')
          : showError(err);
      });
  };

  const deleteStudent = (id) => {
    dispatch(RegistyAPI.listeners.delete(id))
      .then(() => {
        showSuccess();
        fetchListeners();
      })
      .catch((err) => {
        contractInfo?.contractIsPayed
          ? showError('Внимание! Договор оплачен, для изменения списка слушателей необходимо снять оплату')
          : showError(err);
      });
  };

  const changeProgram = (value, id) => {
    dispatch(RegistyAPI.listeners.updateProgram({id, data: value}))
      .then(() => {
        showSuccess();
        fetchListeners();
      })
      .catch((err) => {
        contractInfo?.contractIsPayed
          ? showError('Внимание! Договор оплачен, для изменения списка слушателей необходимо снять оплату')
          : showError(err);
      });
  };

  const deleteProgram = (id) => {
    dispatch(RegistyAPI.listeners.deleteProgram(id))
      .then(() => {
        showSuccess();
        fetchListeners();
      })
      .catch((err) => {
        contractInfo?.contractIsPayed
          ? showError('Внимание! Договор оплачен, для изменения списка слушателей необходимо снять оплату')
          : showError(err);
      });
  };

  const addProgram = (studentId) => (trainingProgramId) => {
    dispatch(RegistyAPI.listeners.addProgram({studentId, trainingProgramId}))
      .then(() => {
        fetchListeners();
        showSuccess('Программа успешно добавлена');
      })
      .catch((err) => {
        contractInfo?.contractIsPayed
          ? showError('Внимание! Договор оплачен, для изменения списка слушателей необходимо снять оплату')
          : showError(err);
      });
  };

  const resetAttemptCount = (studentTrainingId) => () => {
    dispatch(RegistyAPI.listeners.resetAttemptCount({studentTrainingId}))
      .then(() => {
        showSuccess('Кол-во попыток успешно сброшено');
      })
      .catch((err) => {
        showError(err.message);
      });
  };

  const deleteMintrudStudentNumber = (studentId) => {
    dispatch(AdminAPI.documentation.deleteStudentMintrud(studentId))
      .then((res) => showSuccess())
      .catch((err) => showError(err));
  };
  const deleteMintrudStudentTrainingNumber = (studentTrainingId) => {
    dispatch(AdminAPI.documentation.deleteStudentTrainingMintrud(studentTrainingId))
      .then((res) => showSuccess())
      .catch((err) => showError(err));
  };

  const handleChangeQuery = (id) => (values) => {
    const checkboxes = values.checkboxes;
    const changeQuery = values.changeQueryJson;
    const checks = checkboxes instanceof Object ? Object.keys(checkboxes) : [];

    checks.map((check) => (checkboxes[check] === false || !checkboxes[check]) && delete changeQuery[check]);

    if (changeQuery.birthday) {
      changeQuery.birthday = dayjs(changeQuery.birthday, 'DD.MM.YYYY').isValid()
        ? dayjs(changeQuery.birthday, 'DD.MM.YYYY').format('YYYY-MM-DD')
        : dayjs(changeQuery.birthday).format('YYYY-MM-DD');
    }

    dispatch(RegistyAPI.listeners.update({id, data: {...changeQuery}}))
      .then(() => {
        fetchListeners();
        showSuccess();
      })
      .catch((e) => showError(e));
  };

  const editableStudentColumn = (title, key, render, props = {}) => {
    return {
      title: title,
      dataIndex: key,
      key: key,
      align: 'center',
      sorter: (a, b) => compare(a[key], b[key]),
      ...props,
      render: (value, record) => {
        return (
          <div style={{minWidth: props.minWidth}}>
            <EditableCell
              renderValue={value}
              onOk={(value) => changeStudent(value, record.id)}
              valueName={key}
              record={record}
              initialValue={{[key]: value}}
              maxWidth={props.maxWidth}>
              {render}
            </EditableCell>
          </div>
        );
      },
    };
  };

  const isHaveEisotProgram = (trainings = []) => {
    return trainings.find((trn) => trn.trainingProgramTypeIsEisot === true) !== undefined;
  };

  // const isHaveNeedDiplonProgram = (trainings = []) => {
  //   return trainings.find(trn => trn.trainingProgramTypeIsEisot === true) !== undefined;
  // }

  const copyValueToClipboard = (name, value) => {
    if (value === null || value === undefined) {
      return showError(`${name} отсутсвутет`);
    }
    navigator.clipboard.writeText(value);
    showSuccess(`${name} скопиравно в буфер обмена`);
  };

  const columns = [
    {
      title: COMMON.SURNAME,
      dataIndex: 'surname',
      fixed: true,
      key: 'surname',
      align: 'center',
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? '#1890ff' : undefined,
          }}
        />
      ),
      ...listenersSurnameSearch('surname', searchInput, handleSurnameSearch, handleSurnameSearchReset),
      sorter: (a, b) => compare(a['surname'], b['surname']),
      render: (value, record) => {
        const studentChanges = {...record.changeQueryJson};
        delete studentChanges.deleteStudentTrainings;
        delete studentChanges.addTrainingPrograms;
        delete studentChanges.changeStudentTrainings;
        delete studentChanges.programs;
        const changesActive = [];

        for (const key in studentChanges) {
          if (record[key] !== studentChanges[key]) {
            changesActive.push(true);
          }
        }
        return (
          <Tooltip title={record.studentTrainings.map((tr) => tr.trainingProgramName)}>
            <Space>
              {record.changeQueryActive ? (
                <Tooltip title="Все необходимые изменения внесены, больше изменений не требуется?">
                  <Popconfirm
                    placement="left"
                    okText="Да"
                    onConfirm={() => saveChanges(record.id)}
                    title="Все уверены, что хотите сохранить внесенные изменения?">
                    <Button icon={<SaveFilled />} />
                  </Popconfirm>
                </Tooltip>
              ) : null}
              {record.changeQueryActive && changesActive.length ? (
                <ModalForm
                  modalProps={{centered: true}}
                  initialValues={record}
                  onOk={handleChangeQuery(record.id)}
                  formItems={<ChangeQueryItems listener={record} changeQuery={studentChanges} />}
                  title={LISTENERS.CHANGE_QUERY}>
                  <Button danger type="primary" icon={<ExclamationOutlined />} />
                </ModalForm>
              ) : null}

              <div style={{minWidth: '95px'}}>
                <EditableCell
                  renderValue={value}
                  onOk={(value) => changeStudent(value, record.id)}
                  valueName="surname"
                  record={record}
                  initialValue={{surname: value}}>
                  <Input />
                </EditableCell>
              </div>
            </Space>
          </Tooltip>
        );
      },
    },
    editableStudentColumn(COMMON.NAME, 'firstname', <Input />, {fixed: true, minWidth: '50px'}),
    editableStudentColumn(COMMON.SECOND_NAME, 'secondname', <Input />, {minWidth: '100px'}),
    editableStudentColumn(COMMON.JOB_PLACE, 'jobPlace', <Input />, {width: 150}),
    editableStudentColumn(COMMON.PROFESSION, 'profession', <Input.TextArea />, {width: 150}),
    {
      title: `${COMMON.EMAIL} / ${COMMON.PHONE_SHORT}`,
      key: 'email',
      align: 'center',
      render: (_, record) => {
        return (
          <Col style={{minWidth: '70px'}}>
            <Row align={'middle'} justify={'center'}>
              <EditableCell
                renderValue={record.email}
                onOk={(email) => changeStudent(email, record.id)}
                valueName={'email'}
                record={record}
                initialValue={{email: record.email}}>
                <Input type="email" />
              </EditableCell>
            </Row>
            {isHaveEisotProgram(record.studentTrainings) && (
              <Row align={'middle'} justify={'center'} style={{marginTop: 5}}>
                <EditableCell
                  renderValue={record.phone}
                  onOk={(phone) => changeStudent(phone, record.id)}
                  valueName={'phone'}
                  record={record}
                  initialValue={{phone: record.phone}}>
                  <Input />
                </EditableCell>
              </Row>
            )}
          </Col>
        );
      },
    },
    editableStudentColumn(
      COMMON.BIRTHDAY,
      'birthday',
      <StringDatePicker format={'DD.MM.YYYY'} style={{width: '100%'}} />,
      {minWidth: '95px'}
    ),
    editableStudentColumn(DOCUMENTS.SNILS, 'SNILS', <Input />, {minWidth: '60px'}),

    {
      title: DOCUMENTS.NATIONALITY_NAME,
      dataIndex: 'nationalityName',
      key: 'nationalityName',
      align: 'center',
      minWidth: 'min-content',
      sorter: (a, b) => compare(a['nationalityName'], b['nationalityName']),
      render: (value, record) => {
        return (
          <div style={{minWidth: '100px'}}>
            <EditableCell
              renderValue={value}
              onOk={(value) => changeStudent(value, record.id)}
              valueName="nationalityId"
              record={record}
              initialValue={{nationalityId: value}}>
              <Select
                filterOption={(inputValue, option) => option.children.toLowerCase().includes(inputValue.toLowerCase())}
                showSearch
                style={{width: '100%'}}>
                {NATIONALITY_TYPE.map((nationality) => (
                  <Select.Option key={nationality.id} value={nationality.id}>
                    {nationality.label}
                  </Select.Option>
                ))}
              </Select>
            </EditableCell>
          </div>
        );
      },
    },
    editableStudentColumn(COMMON.FULL_NAME, 'datName', <Input />, {minWidth: '100px', maxWidth: '150px'}),
    {
      title: `${DOCUMENTS.INN} / ${DOCUMENTS.OKVED}`,
      key: 'INN',
      align: 'center',
      render: (_, record) => {
        return (
          <Col>
            <Row align={'middle'}>
              <Col style={{width: '65px'}}>
                <Tag
                  onClick={() => {
                    copyValueToClipboard(DOCUMENTS.INN, record.INN);
                  }}
                  style={{cursor: 'pointer'}}
                  color="#87d068">
                  ИНН
                </Tag>
              </Col>
              <Col>
                <EditableCell
                  renderValue={record.INN}
                  onOk={(INN) => changeStudent(INN, record.id)}
                  valueName={'INN'}
                  record={record}
                  initialValue={{INN: record.INN}}>
                  <Input />
                </EditableCell>
              </Col>
            </Row>
            {isHaveEisotProgram(record.studentTrainings) && (
              <Row align={'middle'} style={{marginTop: 5}}>
                <Col style={{width: '65px'}}>
                  <Tag
                    onClick={() => {
                      copyValueToClipboard(DOCUMENTS.OKVED, record.okved);
                    }}
                    color="#FFBF00"
                    style={{cursor: 'pointer'}}>
                    ОКВЭД
                  </Tag>
                </Col>
                <Col>
                  <EditableCell
                    renderValue={record.okved}
                    onOk={(okved) => changeStudent(okved, record.id)}
                    valueName={'okved'}
                    record={record}
                    initialValue={{okved: record.okved}}>
                    <Input />
                  </EditableCell>
                </Col>
              </Row>
            )}
          </Col>
        );
      },
    },
    {
      title: LOCALE.LISTENERS.COURSE_NUMBER,
      dataIndex: 'courseNumber',
      key: 'courseNumber',
      align: 'center',
      sorter: (a, b) => compare(a['courseNumber'], b['courseNumber']),
      width: '100px',
    },
    {
      title: LOCALE.EDUCATION.EXAM,
      dataIndex: 'isExamFinished',
      align: 'center',
      sorter: (a, b) =>
        compare(
          a['studentTrainings'].filter((studentTraining) => studentTraining?.isExamFinished).length,
          b['studentTrainings'].filter((studentTraining) => studentTraining?.isExamFinished).length
        ),
      render: (value, record) => {
        return (
          <Space direction="vertical" style={{minWidth: '80px'}}>
            {record.studentTrainings.map((studentTraining) => {
              if (studentTraining.lastExamDateTime) {
                return (
                  <div key={studentTraining.id} style={{minHeight: '25px', height: '100%', padding: '4px'}}>
                    {studentTraining.isExamFinished ? (
                      <Text type="success">{LOCALE.SYSTEM.PASSED}</Text>
                    ) : (
                      <Text type="danger">{LOCALE.SYSTEM.FAILED}</Text>
                    )}
                  </div>
                );
              } else {
                return (
                  <div key={studentTraining.id} style={{minHeight: '25px', height: '100%', padding: '4px'}}>
                    <Text type="secondary">{LOCALE.SYSTEM.NOT_PASSED}</Text>
                  </div>
                );
              }
            })}
          </Space>
        );
      },
    },
    {
      title: LOCALE.EDUCATION.EXAM_DATE,
      dataIndex: 'lastExamDateTime',
      align: 'center',
      sorter: (a, b) => compare(a['lastExamDateTime'], b['lastExamDateTime']),
      render: (value, record) => {
        return (
          <Space direction="vertical" style={{minWidth: '100px'}}>
            {record.studentTrainings.map((studentTraining) => {
              return (
                <div key={studentTraining.id} style={{minHeight: '25px', height: '100%', padding: '4px'}}>
                  {studentTraining.lastExamDateTime ? (
                    dayjs(studentTraining.lastExamDateTime).format('DD.MM.YYYY')
                  ) : (
                    <Text type="secondary">-</Text>
                  )}
                </div>
              );
            })}
          </Space>
        );
      },
    },
    {
      title: COMMON.DIPLOM,
      align: 'center',
      dataIndex: 'diploms',
      sorter: (a, b) => compare(a['diploms'], b['diploms']),
      render: (diplomas, record) => {
        return !diplomas.length ? (
          <NavLink to={`/diploma/false/${record.id}`}>
            <Text type="danger">Отсутствует</Text>
          </NavLink>
        ) : (
          <Space direction="vertical">
            {diplomas.map((diploma, index) => (
              <NavLink key={diploma.id} to={`/diploma/${diploma.id}/${diploma.studentId}`}>
                {diploma.acceptedDate ? (
                  <Text type="success">{index + 1}. Принят</Text>
                ) : (
                  <Text type="danger">{index + 1}. Не проверен</Text>
                )}
              </NavLink>
            ))}
          </Space>
        );
      },
    },
    {
      title: LOCALE.LISTENERS.CHECK_REASON,
      dataIndex: 'checkReason',
      key: 'checkReason',
      align: 'center',
      sorter: (a, b) => compare(a['checkReason'], b['checkReason']),
      render: (value, record) => {
        return (
          <Space direction="vertical">
            {record.studentTrainings.map((studentTraining) => {
              return (
                <EditableCell
                  key={studentTraining.id}
                  renderValue={studentTraining.checkReason}
                  initialValue={{checkReasonId: studentTraining.checkReason}}
                  valueName={'checkReasonId'}
                  onOk={(value) => changeProgram(value, studentTraining.id)}>
                  <Select style={{width: '100%'}}>
                    {Object.keys(CHECK_REASON).map((reason) => (
                      <Select.Option key={CHECK_REASON[reason].value} value={CHECK_REASON[reason].value}>
                        {CHECK_REASON[reason].name}
                      </Select.Option>
                    ))}
                  </Select>
                </EditableCell>
              );
            })}
          </Space>
        );
      },
    },
    {
      title: LOCALE.LISTENERS.BLANK_NUMBER,
      dataIndex: 'blankNumber',
      key: 'blankNumber',
      align: 'center',
      sorter: (a, b) => compare(a['blankNumber'], b['blankNumber']),
      render: (value, record) => {
        return (
          <Space direction="vertical" style={{minWidth: '50px'}}>
            {record.studentTrainings.map((studentTraining) => {
              return (
                <EditableCell
                  key={studentTraining.id}
                  renderValue={studentTraining?.blankNumber}
                  initialValue={{blankNumber: studentTraining.blankNumber}}
                  valueName={'blankNumber'}
                  onOk={(value) => changeProgram(value, studentTraining.id)}>
                  <Input />
                </EditableCell>
              );
            })}
          </Space>
        );
      },
    },
    {
      title: LOCALE.EDUCATION.PROGRAM,
      dataIndex: 'studentTrainings',
      key: 'studentTrainings',
      align: 'center',
      // sorter: (a, b) => compare(a['studentTrainings'], b['studentTrainings']),
      render: (studentTrainings, record) => {
        const hideButton = studentTrainings.length > 1;

        const addTrainingProgramsList = record.changeQueryJson?.addTrainingPrograms
          ? record.changeQueryJson.addTrainingPrograms.map((training) => training.trainingProgramId)
          : [];

        const filteredTrainingProgramList = addTrainingProgramsList.filter((trainingProgram) => {
          const listenerPrograms = record.studentTrainings.map((training) => training.trainingProgramId);
          return !listenerPrograms.includes(trainingProgram);
        });

        // const sortedTrainings = [...studentTrainings].sort((a, b) => {
        //   const nameA = a.trainingProgramName.toUpperCase();
        //   const nameB = b.trainingProgramName.toUpperCase();
        //   if (nameA < nameB) {
        //     return -1;
        //   }
        //   if (nameA > nameB) {
        //     return 1;
        //   }
        //   return 0;
        // });

        return (
          <Space size="large">
            <Space direction="vertical">
              {studentTrainings.map((studentTraining) => {
                const isChangeRequired =
                  studentTraining.changeQueryNewTrainingProgramId &&
                  studentTraining.changeQueryNewTrainingProgramId !== studentTraining.trainingProgramId;

                const deleteStudentTrainingsList = record.changeQueryJson?.deleteStudentTrainings
                  ? record.changeQueryJson.deleteStudentTrainings.map((training) => training.studentTrainingId)
                  : [];
                const isRequestDelete = deleteStudentTrainingsList.includes(studentTraining.id);
                return (
                  <Space
                    key={studentTraining.id}
                    size={24}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <EditableCell
                      renderValue={
                        <Tooltip title={studentTraining.trainingProgramFullName}>
                          {studentTraining.trainingProgramName}
                        </Tooltip>
                      }
                      valueName="trainingProgramId"
                      onToggle={(edit) => edit && fetchPrograms(studentTraining.trainingProgramTypeId)}
                      onOk={(value) => changeProgram(value, studentTraining.id)}
                      initialValue={{trainingProgramId: studentTraining.trainingProgramName}}>
                      <Select
                        filterOption={(inputValue, option) =>
                          option.children.props.children.toLowerCase().includes(inputValue.toLowerCase())
                        }
                        showSearch
                        loading={programsLoading}
                        style={{width: '100%'}}>
                        {programs.map((program) => (
                          <Select.Option key={program.id} value={program.id}>
                            <Badge
                              dot={program.id === studentTraining.changeQueryNewTrainingProgramId}
                              title="Программа на замену текущей">
                              {program.name}
                            </Badge>
                          </Select.Option>
                        ))}
                      </Select>
                    </EditableCell>
                    <Space>
                      {isChangeRequired && (
                        <Popconfirm
                          onConfirm={() =>
                            changeProgram(
                              {trainingProgramId: studentTraining.changeQueryNewTrainingProgramId},
                              studentTraining.id
                            )
                          }
                          title="Изменить программу обучения?"
                          okText="Да">
                          <Badge dot title="Запрос на изменение программы" style={{width: 8, height: 8}}>
                            <Button style={{marginLeft: 5}} danger icon={<ExclamationOutlined />} />
                          </Badge>
                        </Popconfirm>
                      )}
                      {hideButton && (
                        <Popconfirm
                          onConfirm={() => deleteProgram(studentTraining.id)}
                          title="Удалить программу обучения?"
                          okText="Да">
                          {isRequestDelete ? (
                            <Badge
                              dot={isRequestDelete}
                              title="Запрос на удаление программы"
                              style={{width: 8, height: 8}}>
                              <Button danger icon={<ExclamationOutlined />} type="primary" />
                            </Badge>
                          ) : (
                            <Button danger icon={<DeleteOutlined />} type="primary" />
                          )}
                        </Popconfirm>
                      )}
                      <Tooltip title="Сбросить количество попыток сдачи экзамена?">
                        <Popconfirm
                          onConfirm={resetAttemptCount(studentTraining.id)}
                          title="Вы уверены что хотите обнулить количество попыток?"
                          okText="Да">
                          <Button icon={<SyncOutlined />} />
                        </Popconfirm>
                      </Tooltip>
                      {adminInfo.isOperator ? (
                        <Tooltip title="Удалить номер Минтруда по указанному курсу?">
                          <Popconfirm
                            onConfirm={() => deleteMintrudStudentTrainingNumber(studentTraining.id)}
                            title="Вы уверены ?"
                            okText="Да">
                            <Button icon={<FileExclamationOutlined />} danger />
                          </Popconfirm>
                        </Tooltip>
                      ) : null}
                    </Space>
                  </Space>
                );
              })}
            </Space>
            {filteredTrainingProgramList.length ? (
              <ModalForm
                formItems={
                  <AddNewProgramsForm
                    addProgram={addProgram(record.id)}
                    addTrainingProgramsList={filteredTrainingProgramList}
                  />
                }
                modalProps={{okButtonProps: {style: {display: 'none'}}}}
                title={LISTENERS.ADD_PROGRAM}>
                <Badge dot title="Запрос на добавлние программы" style={{width: 8, height: 8}}>
                  <Button type="primary" icon={<PlusOutlined />}>
                    Добавить
                  </Button>
                </Badge>
              </ModalForm>
            ) : (
              <ModalForm
                onOk={addProgram(record.id)}
                formItems={<NewProgramForm />}
                title={LISTENERS.ADD_PROGRAM}
                modalProps={{okText: 'Добавить'}}>
                <Button type="primary" icon={<PlusOutlined />}>
                  Добавить
                </Button>
              </ModalForm>
            )}
          </Space>
        );
      },
    },
    {
      title: (
        <ModalForm onOk={addStudent} formItems={<NewListenerForm />} title={LISTENERS.NEW_STUDENT}>
          <Button type="primary" icon={<PlusOutlined />} />
        </ModalForm>
      ),
      align: 'center',
      width: 50,
      dataIndex: 'id',
      render: (id, record) => (
        <Space key={id}>
          <Popconfirm placement="left" onConfirm={() => deleteStudent(id)} title={MESSAGES.CONFIRM}>
            <Button icon={<DeleteOutlined />} type="primary" danger />
          </Popconfirm>
          {adminInfo.isOperator ? (
            <Tooltip title="Удалить номер Минтруда у обучаемого">
              <Popconfirm placement="left" onConfirm={() => deleteMintrudStudentNumber(id)} title={MESSAGES.CONFIRM}>
                <Button icon={<FileExclamationOutlined />} danger />
              </Popconfirm>
            </Tooltip>
          ) : null}
        </Space>
      ),
    },
  ];

  return (
    <Table
      loading={loading}
      pagination={false}
      className="black_bordered listeners-table"
      bordered={true}
      sticky
      scroll={{x: 'max-content'}}
      dataSource={listeners}
      size="small"
      columns={columns}
      rowKey="id"
    />
  );
};

export default ListenersTable;
