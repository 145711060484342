import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {Button, Space} from 'antd';

import {RegistyAPI} from '../../store/RegistryStore/methods';
import FilterPanel from '../../components/FilterPanel';
import ColumnSettings from '../../components/Registry/ColumnSettings';
import RegistryTable from '../../components/Registry/RegistryTable';
import RegistryFilters from '../../components/Registry/RegistryFilters';
import PageLayout from '../../components/Layout/PageLayout';
import UploadTracks from '../../components/Registry/UploadTracks';

import {ADMIN_RIGHTS, LOCALE} from '../../common';
import PostReportModal from '../../components/Registry/PostReportModal';
import MintrudReportModal from '../../components/Registry/MintrudReportModal';
import MintrudNumbersModal from '../../components/Registry/MintrudNumbersModal';
import {registryFilters} from '../../components/filters';
import {AdminAPI} from '../../store/AdminStore/methods';
import {AdminSelectors} from '../../store/AdminStore';
import EisotReportModal from '../../components/Registry/EisotReportModal';
import dayjs from 'dayjs';
import PaymentDocumentsModal from '../../components/Registry/PaymentDocumentsModal';

const defaultFilters = {
  limit: 20,
  offset: 0,
  page: 1,
  inArchive: false,
  dateCheck: true,
  status: ['white', 'yellow', 'blue', 'red', 'problem', 'inplace'],
  dateType: 'primary',
  date: 'custom',
  dateFrom: '01.01.2020',
  dateTo: dayjs().format('DD.MM.YYYY'),
  contractNumberFilter: false,
  contractNumber: '',
  sourceFilter: false,
  costFilter: false,
  isPayedFilter: false,
  isPayed: false,
  isExamFinishedFilter: false,
  isExamFinished: false,
  isNeedDiplomCheck: false,
  deliveryFilter: false,
  programFilter: false,
  programTypeFilter: false,
  deadSoulFilter: false,
  operatorFilter: false,
  payedNotDoneFilter: false,
  manualAddressFilter: false,
  duplicateFilter: false,
  isStudentChangeQueryActive: false,
};

const resetFilters = {
  sourceTypeId: null,
  deliveryTypeId: null,
  page: 1,
  operatorId: null,
  programId: null,
  programTypeId: null,
  deadSoulStatusId: null,
};

const {REGISTRY} = LOCALE;
const Registry = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const settings = useSelector(AdminSelectors.companySettings);
  const adminInfo = useSelector(AdminSelectors.adminInfo);

  const savedFilters = localStorage.getItem('registryFilters');
  const registryFiltersPagination = localStorage.getItem('registryFiltersPagination');
  const initialFilters = {...defaultFilters, ...JSON.parse(savedFilters), ...JSON.parse(registryFiltersPagination)};

  const fetchContracts = (filters = initialFilters) => {
    const limit = filters.limit ? Number(filters.limit) : 500;
    const paramsPage = searchParams.get('page');
    const offset = paramsPage ? Number(paramsPage - 1) * Number(filters?.limit) : 0;

    if (filters.dateFrom) {
      if (dayjs(filters.dateFrom, 'DD.MM.YYYY').isValid()) {
        dayjs(filters.dateFrom, 'DD.MM.YYYY').format('DD-MM-YYYY');
      } else {
        filters.dateFrom = dayjs(filters.dateFrom).format('DD-MM-YYYY');
      }
    }

    if (filters.dateTo) {
      if (dayjs(filters.dateTo, 'DD.MM.YYYY').isValid()) {
        dayjs(filters.dateTo, 'DD.MM.YYYY').format('DD-MM-YYYY');
      } else {
        filters.dateTo = dayjs(filters.dateTo).format('DD-MM-YYYY');
      }
    }

    delete filters.limit;
    delete filters.offset;

    dispatch(RegistyAPI.registry.getAll({...filters, pagination: {limit, offset}}));
  };

  const filterLabels = {...registryFilters};

  if (initialFilters.dateCheck) {
    filterLabels.date = registryFilters.date;
    filterLabels.dateType = registryFilters.dateType;
  } else {
    delete filterLabels.date;
    delete filterLabels.dateType;
  }

  useEffect(() => {
    fetchContracts(initialFilters);
  }, [initialFilters]);

  useEffect(() => {
    dispatch(AdminAPI.companySettings.getAll());
    dispatch(AdminAPI.operator.getAll());
  }, []);

  const showChangeQueries = () => {
    setSearchParams({...initialFilters, isStudentChangeQueryActive: true});
  };

  const haveAccessToPaymentDocuments = adminInfo?.adminRights?.includes(ADMIN_RIGHTS.autoPay.id);

  return (
    <PageLayout pageTitle={LOCALE.PAGE_TITLES.REGISTRY}>
      <FilterPanel
        initialFilters={initialFilters}
        resetFilters={{...defaultFilters, ...resetFilters}}
        filterLabels={filterLabels}
        name="registryFilters">
        <RegistryFilters />
      </FilterPanel>
      <Space wrap={true}>
        <PostReportModal />
        <UploadTracks />
        <Button onClick={showChangeQueries}>{REGISTRY.SHOW_CHANGE_QUERIES}</Button>
        {settings.showMinTrudButtons ? (
          <>
            <MintrudReportModal />
            <MintrudNumbersModal />
          </>
        ) : null}
        <EisotReportModal />
        {haveAccessToPaymentDocuments && <PaymentDocumentsModal />}
        <ColumnSettings />
      </Space>
      <RegistryTable fetchContracts={fetchContracts} />
    </PageLayout>
  );
};

export default Registry;
