import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import {Button, Form, Select, Space, Typography} from 'antd';
import {RegistyAPI} from '../../store/RegistryStore/methods';
import {RegistySelectors} from '../../store/RegistryStore';
import UseMessage from '../hooks/useMessage';
import ModalForm from '../UX/ModalForm';
import PopConfirmButton from '../UX/PopConfirmButton';

const ContractRelation = () => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const {id: relationId, contracts} = useSelector(RegistySelectors.relatedContracts);
  const relatedContractsFull = useSelector(RegistySelectors.relatedContractsFull);

  const {showError, showSuccess} = UseMessage();

  useEffect(() => {
    dispatch(RegistyAPI.contractRelations.checkRelations(id));
    dispatch(RegistyAPI.contractRelations.availableRelations(id));
  }, []);

  const addContractToRelation = ({relationContractId}) => {
    dispatch(RegistyAPI.contractRelations.addContractToRelation({id, relationContractId}))
      .then(() => {
        dispatch(RegistyAPI.contractRelations.checkRelations(id));
        showSuccess('Договор успешно привязан');
      })
      .catch((err) => showError('Вначале отвяжите договор от текущей связки'));
  };
  const deleteContractFromRelation = () => {
    dispatch(RegistyAPI.contractRelations.deleteContractFromRelation(id))
      .then(() => {
        showSuccess('Договор из связки успешно удален');
        dispatch(RegistyAPI.contractRelations.checkRelations(id));
      })
      .catch((err) => showError('Связка не найдена'));
  };

  const deleteRelation = () => {
    dispatch(RegistyAPI.contractRelations.deleteRelation(id))
      .then(() => {
        showSuccess('Связка успешно удалена');
        dispatch(RegistyAPI.contractRelations.checkRelations(id));
      })
      .catch((err) => showError('Связка не найдена'));
  };

  const options = relatedContractsFull.map((contract) => ({
    value: contract.relationContractId,
    label: contract.number,
  }));

  return (
    <Space size="middle" align="center" wrap>
      {relationId ? (
        <>
          <Space>
            {contracts?.map((contract, index) => {
              return (
                <Link reloadDocument key={contract.id} to={`/registry/students/${contract.id}`}>
                  {contract.number} {index < contracts.length - 1 ? ', ' : null}
                </Link>
              );
            })}
          </Space>
          <PopConfirmButton
            type="primary"
            danger
            title="Удалить?"
            onClick={deleteContractFromRelation}
            button=" Удалить из связки"
          />
          <PopConfirmButton danger title="Удалить?" onClick={deleteRelation} button="Удалить целиком" />
        </>
      ) : (
        <Space>
          <Typography>Связанные договора отсутствуют</Typography>
          <ModalForm
            formItems={
              <Form.Item name="relationContractId">
                <Select options={options} placeholder="2023-00Д" allowClear />
              </Form.Item>
            }
            onOk={addContractToRelation}
            title="Доступные договора для связки">
            <Button type="primary">Добавить договор в связку</Button>
          </ModalForm>
        </Space>
      )}
    </Space>
  );
};
export default ContractRelation;
